import React, { useState, useEffect, lazy, Suspense } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import firebaseApp from "./config/firebase";
import NavBar from "./components/NavBar";
import LeftMenu from "./components/LeftMenu";
import styled from "styled-components";
import Spinner from "./components/Spinner"; // Import Spinner component
import GoogleAPIProvider from "./components/GoogleAPIProvider"; // Adjust the import path as necessary
import CheckEmailVerification from './components/CheckEmailVerification';
import dotenv from 'dotenv';

const AppContainer = styled.div`
  position: relative;
`;

const FixedNavBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

const ContentContainer = styled.div`
  margin-left: 250px;
  width: calc(100% - 250px);

  @media screen and (max-width: 767px) {
    margin-left: 0;
    width: 100%;
  }
`;

const LeftMenuContainer = styled.div`
  @media screen and (min-width: 768px) {
    display: block;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const MyAssets = lazy(() => import("./components/MyAssets"));
const ManageAssets = lazy(() => import("./components/ManageAssets"));
const Insight = lazy(() => import("./components/Insight"));
const CategoryInsight = lazy(() => import("./components/CategoryInsight"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));
const AboutUs = lazy(() => import("./components/AboutUs"));
const IntegritetspolicyModal = lazy(() => import("./components/IntegritetsPolicy"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const HomePage = lazy(() => import("./pages/HomePage"));
const RegisterPage = lazy(() => import("./pages/RegisterPage"));
const AboutOurSystem = lazy(() => import("./components/AboutOurSystem"));

function App() {
  const [user, setUser] = useState(null);
  const [navHeight, setNavHeight] = useState(0);
  const [checkingAuth, setCheckingAuth] = useState(true);
  const location = useLocation(); // Get the current location
  dotenv.config();
 
  useEffect(() => {
    const unsubscribe = firebaseApp.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = firebaseApp.firestore().collection("users").doc(user.uid);
        try {
          const doc = await userRef.get();
          if (doc.exists) {
            setUser({
              ...user,
              name: doc.data().name || user.email.split('@')[0],
              emailVerified: user.emailVerified,
            });
          } else {
            setUser({
              ...user,
              name: user.email.split('@')[0],
              emailVerified: user.emailVerified,
            });
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
          setUser({
            ...user,
            name: user.email.split('@')[0],
            emailVerified: user.emailVerified,
          });
        }
      } else {
        setUser(null);
      }
      setCheckingAuth(false);
    });

    return () => unsubscribe();
  }, []);

  if (checkingAuth) {
    return <Spinner />;
  }

  const handleCloseNavLinks = () => {
    // Add your logic to handle closing the navigation links
  };

  // Define the title based on the current location
  let pageTitle = "Välkommen till X-elor!"; // Default title
  switch (location.pathname) {
    case "/":
      pageTitle = "Start - X-elor";
      break;
    case "/manage/assets":
      pageTitle = "Hantera tillgångar - X-elor";
      break;
    case "/assets/overview":
      pageTitle = "Översikt över tillgångar - X-elor";
      break;
    case "/insights/real-estate":
      pageTitle = "Insikter om fastigheter - X-elor";
      break;
    case "/insights/total":
      pageTitle = "Totala insikter - X-elor";
      break;
    case "/settings":
      pageTitle = "Inställningar - X-elor";
      break;
    case "/about":
      pageTitle = "Om oss - X-elor";
      break;
    case "/terms":
      pageTitle = "Användarvillkor - X-elor";
      break;
    default:
      break;
  }
  
  // Redirect if user is not authenticated and tries to access protected routes
  const isAuthenticated = !!user;
  const isProtectedRoute = /^\/(manage\/assets|assets\/overview|insights\/real-estate|insights\/total|settings)/.test(location.pathname);
  
  if (!isAuthenticated && isProtectedRoute) {
    return <Navigate to="/register" />;
  }
  
  return (
    <GoogleAPIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <div>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        {user ? (
          user.emailVerified ? (
            // User is authenticated and email is verified
            <AppContainer>
              <FixedNavBar id="navbar">
              <NavBar isAuthenticated={!!user} userName={user ? (user.name || "Default name") : "Guest"} />
              </FixedNavBar>
              <LeftMenuContainer>
                <LeftMenu />
              </LeftMenuContainer>
              <ContentContainer navHeight={navHeight}>
                <Suspense fallback={<Spinner />}>
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/manage/assets" element={<ManageAssets />} />
                    <Route path="/assets/overview" element={<MyAssets />} />
                    <Route path="/insights/real-estate" element={<Insight />} />
                    <Route path="/insights/total" element={<CategoryInsight />} />
                    <Route path="/settings" element={<ProfilePage />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/terms" element={<IntegritetspolicyModal />} />
                    {/* More routes for authenticated users */}
                  </Routes>
                </Suspense>
              </ContentContainer>
            </AppContainer>
          ) : (
            // User is authenticated but email is not verified
            <Suspense fallback={<Spinner />}>
              <Routes>
                <Route path="/" element={<CheckEmailVerification />} />
              </Routes>
            </Suspense>
          )
        ) : (
          // User is not authenticated
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/about" element={<AboutOurSystem />} />
              <Route path="/terms" element={<IntegritetspolicyModal />} />

              {/* No need to directly route to /verify-email here since it's handled above */}
            </Routes>
          </Suspense>
        )}
        
      </div>
    </GoogleAPIProvider>
  );
}

export default App;
