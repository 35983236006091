import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid black;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 2s linear infinite;
  position: fixed; // Added
  left: 50%; // Added
  top: 50%; // Added
  transform: translate(-50%, -50%); // Added
`;

const Spinner = () => {
  return <StyledSpinner />;
};

export default Spinner;

