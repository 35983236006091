import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import '../styles/NavBar.css';
import { FaBars, FaUser, FaAngleDown, FaTimes } from 'react-icons/fa';
import MobileMenu from './MobileMenu';
import { useNavigate } from 'react-router-dom';
import firebaseApp from '../config/firebase';
import logo from '../images/crowns.png'; // Import your image here


const NavBar = ({ isAuthenticated, userName }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleResize = useCallback(() => {
    if (window.innerWidth > 768 && showMenu) {
      setShowMenu(false);
    }
  }, [showMenu]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const handleLogout = async () => {
    try {
      await firebaseApp.auth().signOut();
      navigate('/');
    } catch (error) {
      console.error('Fel vid utloggning:', error);
      alert('Det gick inte att logga ut, försök igen.');
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const closeMenu = () => setShowMenu(false); // Function to close the menu

  return (
    <>
      <nav className={`navbar ${showMenu ? 'open' : ''}`}>
        <Link to="/" className="navbar-logo">
          <img src={logo} alt="Logo" className="logo-image" />
        </Link>
        {isAuthenticated && (
          <div className="navbar-toggle" onClick={toggleMenu}>
            {showMenu ? <FaTimes /> : <FaBars />}
          </div>
        )}
        <ul className={`navbar-links ${showMenu ? 'show' : ''} ${!isAuthenticated ? 'center-links' : ''}`}>
          {isAuthenticated ? (
            <li className="navbar-link profile-section" onClick={toggleDropdown}>
              <div className="profile-icon-wrapper">
                <FaUser />
              </div>
              <span className="profile-name">{userName}</span>
              <FaAngleDown className="arrow" />
              <ul className={`dropdown-menu ${showDropdown ? 'show' : ''}`}>
                <li>
                  <Link to="/settings">Inställningar</Link>
                </li>
                <li>
                  <button onClick={handleLogout} className="logout-btn">
                    Logga ut
                  </button>
                </li>
              </ul>
            </li>
          ) : (
            <>
            <div className="navbar-link profile-section">
            <li className={`navbar-link ${!isAuthenticated ? 'right-align' : ''}`}>
              <Link to="/">Hem</Link>
            </li>
            <li className={`navbar-link ${!isAuthenticated ? 'right-align' : ''}`}>
              <Link to="/register">Registrera</Link>
            </li>
            <li className={`navbar-link ${!isAuthenticated ? 'right-align' : ''}`}>
              <Link to="/about">Om oss</Link>
            </li>
            </div>
          </>
          
          )}
        </ul>
        {isAuthenticated && showMenu && <MobileMenu onClose={closeMenu} />}
      </nav>
      <div className={`overlay ${showMenu ? 'open' : ''}`} onClick={closeMenu}></div>
    </>
  );
};

export default NavBar;
