

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "blackbox-156fd.firebaseapp.com",
    projectId: "blackbox-156fd",
    storageBucket: "blackbox-156fd.appspot.com",
    messagingSenderId: "421525305323",
    appId: "1:421525305323:web:e7c7b74749be59f2f40bae",
    measurementId: "G-MZYGEM224N"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export default firebaseApp;
