// Importera React och nödvändiga hooks och komponenter
import React, { useEffect, useState } from 'react';
import firebaseApp from '../config/firebase';
import styled from 'styled-components';
import logo from '../images/crowns.png'; // Importera din logobild

// Styled components
const FullScreenCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 0 5%; // Minskat padding för mindre skärmar
  box-sizing: border-box; // Inkludera padding i bredd/höjdberäkningar

  @media (min-width: 768px) {
    padding: 0 10%; // Ökat padding för större skärmar
  }
`;

const Logo = styled.img`
  width: 150px; // Justera bredden på logotypen vid behov
  margin-bottom: 20px; // Lägg till marginal för att separera logotypen från annat innehåll
`;

const Title = styled.h1`
  color: #333;
  font-size: 20px; // Mindre fontstorlek för mobilen
  margin: 20px 0; // Ökad marginal för bättre avstånd

  @media (min-width: 768px) {
    font-size: 24px; // Återgå till ursprunglig fontstorlek för större skärmar
  }
`;

const Message = styled.p`
  color: #6c757d;
  font-size: 14px; // Minskad fontstorlek för mobilen
  text-align: center; // Centrera texten för bättre läsbarhet på mobilen
  margin: 0 0 20px; // Lägg till bottenmarginal för avstånd

  @media (min-width: 768px) {
    font-size: 16px; // Återgå till ursprunglig fontstorlek för större skärmar
  }
`;

const VerifiedMessage = styled.div`
  color: #4bb543;
  font-size: 16px; // Justera fontstorlek för tydlighet på mobilen
  margin-top: 20px;
  text-align: center; // Se till att texten är centrerad för konsistens

  @media (min-width: 768px) {
    font-size: 18px; // Återgå till ursprunglig fontstorlek för större skärmar
  }
`;

const Button = styled.button`
  background-color: #6c757d; // Justerad knappfärg
  color: white;
  padding: 12px 24px; // Öka padding för ett större klickbart område på mobilen
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px; // Minska fontstorlek för att passa mindre skärmar
  margin-top: 20px;
  transition: background-color 0.2s; // Jämn övergång för hover-effekt

  &:hover {
    background-color: #5a6268; // Justerad hoverfärg
  }

  @media (min-width: 768px) {
    font-size: 16px; // Återgå till ursprunglig fontstorlek för större skärmar
  }
`;

const CheckEmailVerification = () => {
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    let intervalId;

    const user = firebaseApp.auth().currentUser;
    const checkEmailVerification = async () => {
      if (user) {
        await user.reload();
        setVerified(user.emailVerified);
        if (user.emailVerified) {
          // Använder window.location.href för en fullständig sidomladdning till startsidan
          window.location.href = '/';
        }
      }
    };

    const unsubscribe = firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        checkEmailVerification();
        // Sätt ett intervall för att kontrollera e-postverifieringsstatus var 5:e sekund
        intervalId = setInterval(checkEmailVerification, 5000);
      }
    });

    return () => {
      unsubscribe();
      if (intervalId) {
        clearInterval(intervalId); // Rensa intervallet när komponenten avmonteras
      }
    };
  }, []);

  const resendVerificationEmail = async () => {
    const user = firebaseApp.auth().currentUser;
    if (user && !user.emailVerified) { // Kontrollera om användaren inte redan är verifierad
      try {
        const actionCodeSettings = {
          url: 'https://x-elor.com',
          handleCodeInApp: false
        };
        await user.sendEmailVerification(actionCodeSettings);
        alert('Verifieringsmail skickat igen. Vänligen kontrollera din inkorg.');
      } catch (error) {
        console.error('Fel vid skickande av verifieringsmail:', error);
        alert('Ett fel inträffade när verifieringsmailet skulle skickas.');
      }
    }
  };

  // Funktion för att radera användare och omdirigera till startsidan
 const handleDeleteUser = () => {
  const user = firebaseApp.auth().currentUser;
  if (user) {
    // Omdirigera först till startsidan
    window.location.href = '/';
    // Starta användarraderingsprocessen
    user.delete()
  }
};

  return (
    <FullScreenCenter>
      <Logo src={logo} alt="Din Logotyp" /> {/* Använd din logobild */}
      {verified ? (
        <VerifiedMessage>E-post verifierad! Omdirigerar...</VerifiedMessage>
      ) : (
        <>
          <Title>Vänligen verifiera din e-postadress</Title>
          <Message>Kontrollera din e-post för verifieringslänken. Klicka på knappen nedan för att skicka verifieringsmailet igen. Sidan kommer att uppdateras automatiskt när din e-post har verifierats.</Message>
          <Button onClick={resendVerificationEmail}>Skicka igen</Button>
          <Button onClick={handleDeleteUser}>Börja Om</Button>
          <Message>Observera att verifieringsmailet kan hamna i din e-posts skräppost eftersom varumärket kanske inte är känt för Google ännu.</Message>
        </>
      )}
    </FullScreenCenter>
  );
};

export default CheckEmailVerification;
