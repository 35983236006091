import React, { useState, useEffect, createContext, useContext } from 'react';

// Context to provide Google API objects (maps, places) to child components
const GoogleAPIContext = createContext(null);

export const useGoogleAPI = () => useContext(GoogleAPIContext);

const GoogleAPIProvider = ({ apiKey, children }) => {
  const [googleAPI, setGoogleAPI] = useState({
    mapsLoaded: false,
    maps: null,
    placesLoaded: false,
    places: null,
  });

  useEffect(() => {
    const scriptId = 'google-maps-script';

    const loadScript = () => {
      if (document.getElementById(scriptId)) {
        console.log("Google Maps Script already loaded");
        return; // Script is already loaded
      }

      const script = document.createElement('script');
      script.id = scriptId;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      script.onload = () => {
        setGoogleAPI({
          mapsLoaded: true,
          maps: window.google.maps,
          placesLoaded: true,
          places: window.google.maps.places,
        });
      };
    };

    loadScript();

    return () => {
      // Remove script when component unmounts to clean up
      const script = document.getElementById(scriptId);
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [apiKey]);

  return (
    <GoogleAPIContext.Provider value={googleAPI}>
      {children}
    </GoogleAPIContext.Provider>
  );
};

export default GoogleAPIProvider;
