import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import logo from '../images/crowns.png';

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const MobileContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2a2a2a;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  animation: ${slideIn} 0.3s ease-out forwards;
  overflow-y: auto;
`;

const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const MobileMenuItem = styled(NavLink)`
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  padding: 15px;
  border-bottom: 1px solid #3f3f3f;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover, &.active {
    background-color: #B8860B; // Gold background for active/hover state
    color: #2a2a2a; // Dark text for contrast
  }
`;

const MobileSubMenu = styled.div`
  display: ${props => props.show ? 'block' : 'none'};
  padding-left: 20px;
`;

const ArrowIcon = styled.div`
  display: flex;
`;

const MobileFooter = styled.div`
  margin-top: auto;
  padding: 20px;
  background-color: #1a1a1a;
  text-align: center;
`;

const Logo = styled.img`
  width: 100px;
`;

const MobileMenu = ({ onClose }) => {
  const [showAssetsSubmenu, setShowAssetsSubmenu] = useState(false);
  const [showInsightsSubmenu, setShowInsightsSubmenu] = useState(false);

  const toggleAssetsSubmenu = () => setShowAssetsSubmenu(!showAssetsSubmenu);
  const toggleInsightsSubmenu = () => setShowInsightsSubmenu(!showInsightsSubmenu);

  return (
    <MobileContainer>
      <MobileContent>
        <MobileMenuItem to="/" exact activeClassName="active" onClick={onClose}>Start</MobileMenuItem>
        <MobileMenuItem as="div" onClick={toggleAssetsSubmenu}>
          Mina Tillgångar
          <ArrowIcon>{showAssetsSubmenu ? <FaAngleDown /> : <FaAngleRight />}</ArrowIcon>
        </MobileMenuItem>
        <MobileSubMenu show={showAssetsSubmenu}>
          <MobileMenuItem to="/assets/overview" activeClassName="active" onClick={onClose}>Tillgångar</MobileMenuItem>
          <MobileMenuItem to="/manage/assets" activeClassName="active" onClick={onClose}>Hantera Tillgångar</MobileMenuItem>
        </MobileSubMenu>
        <MobileMenuItem as="div" onClick={toggleInsightsSubmenu}>
          Insikter
          <ArrowIcon>{showInsightsSubmenu ? <FaAngleDown /> : <FaAngleRight />}</ArrowIcon>
        </MobileMenuItem>
        <MobileSubMenu show={showInsightsSubmenu}>
          <MobileMenuItem to="/insights/real-estate" activeClassName="active" onClick={onClose}>Fastighets Insikter</MobileMenuItem>
          <MobileMenuItem to="/insights/total" activeClassName="active" onClick={onClose}>Totala Insikter</MobileMenuItem>
        </MobileSubMenu>
        <MobileMenuItem to="/settings" activeClassName="active" onClick={onClose}>Inställningar</MobileMenuItem>
        <MobileMenuItem to="/about" activeClassName="active" onClick={onClose}>Om oss</MobileMenuItem>
      </MobileContent>
      <MobileFooter>
        <Logo src={logo} alt="Logo" />
      </MobileFooter>
    </MobileContainer>
  );
};

export default MobileMenu;
