import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa'; // Import icons for menu arrows
import logo from '../images/crown.png'; // Import your logo image

const Container = styled.div`
  position: fixed;
  top: 40px;
  bottom: 0;
  width: 250px;
  background-color: #2a2a2a;
  color: #ffffff;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-bottom: 1px solid #3f3f3f;
  background-color: #222;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 20px;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const MenuItem = styled.div`
  font-size: 15px;
  color: #ffffff; // Default text color
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 2px solid transparent;
  height: 40px;
  cursor: pointer;

  &:hover {
    border-color: #ffffff; // White border on hover for visibility
  }

  &.active {
    font-weight: bold;
    background-color: #2a2a2a; // Keeps the background consistent with non-active state
    color: #ffffff; // Keeps text color white for consistency
    border-color: #FFD700; // Golden ring around the active item
  }
`;

const SubMenu = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: column;
  padding-left: 20px;
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid #3f3f3f;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
`;

const ArrowIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LeftMenu = () => {
  const [showAssetsSubmenu, setShowAssetsSubmenu] = useState(false);
  const [showInsightsSubmenu, setShowInsightsSubmenu] = useState(false);

  const toggleAssetsSubmenu = () => setShowAssetsSubmenu(!showAssetsSubmenu);
  const toggleInsightsSubmenu = () => setShowInsightsSubmenu(!showInsightsSubmenu);

  return (
    <Container>
      <Header>
        <Title>Meny</Title>
      </Header>
      <Content>
        <NavLink to="/" exact activeClassName="active">
          <MenuItem>Start</MenuItem>
        </NavLink>
        <MenuItem onClick={toggleAssetsSubmenu}>
          Mina Tillgångar
          <ArrowIcon>{showAssetsSubmenu ? <FaAngleDown /> : <FaAngleRight />}</ArrowIcon>
        </MenuItem>
        <SubMenu show={showAssetsSubmenu}>
          <NavLink to="/assets/overview" activeClassName="active">
            <MenuItem>Tillgångar</MenuItem>
          </NavLink>
          <NavLink to="/manage/assets" activeClassName="active">
            <MenuItem>Hantera Tillgångar</MenuItem>
          </NavLink>
        </SubMenu>
        <MenuItem onClick={toggleInsightsSubmenu}>
          Insikter
          <ArrowIcon>{showInsightsSubmenu ? <FaAngleDown /> : <FaAngleRight />}</ArrowIcon>
        </MenuItem>
        <SubMenu show={showInsightsSubmenu}>
          <NavLink to="/insights/real-estate" activeClassName="active">
            <MenuItem>Fastighets Insikter</MenuItem>
          </NavLink>
          <NavLink to="/insights/total" activeClassName="active">
            <MenuItem>Totala Insikter</MenuItem>
          </NavLink>
        </SubMenu>
      
        <NavLink to="/settings" activeClassName="active">
          <MenuItem>Inställningar</MenuItem>
        </NavLink>
        
        <NavLink to="/about" activeClassName="active">
          <MenuItem>Om oss</MenuItem>
        </NavLink>
      </Content>
      <Footer>
        <Logo src={logo} alt="Logo" />
      </Footer>
    </Container>
  );
};

export default LeftMenu;
