import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import { Buffer } from 'buffer';
import './index.css';
import { AuthProvider } from './context/AuthContext'; // Make sure the path to AuthContext is correct

// Correct way to globally assign Buffer to window if needed
window.Buffer = Buffer;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <AuthProvider> {/* Wrap App with AuthProvider */}
      <App />
    </AuthProvider>
  </Router>
);

// Example of a performance logging function, uncomment to use
// function reportWebVitals(consoleLog) {
//   consoleLog('Web vitals reported');
// }

// To enable performance measurements
// reportWebVitals(console.log);
